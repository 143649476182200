import React from 'react'
import DlPdfLogo from '../assets/dl-pdf.svg'

const PdfLink = ({ target }) => (
  <div style={{ display: "inline-block", margin: "0px 0.4rem", width: "20px", }}>
    <a href={`../../papers/${target}.pdf`}>
      <img style={{ marginBottom: 0 }} src={DlPdfLogo} alt="Download paper PDF" title="Download paper PDF" />
    </a>
  </div>
)

export default PdfLink
