import React from 'react'
// import Link from 'gatsby-link'
import Photo from '../assets/photo.png'
import Layout from '../components/Layout'
import Seo from '../components/SEO'
import PdfLink from '../components/PdfLink'
import PptLink from '../components/PptLink'

// Create pageData object to simulate markdownRemark object
const pageData = {
  frontmatter: {
    title: "Muhammad Iqbal C. Rochman",
    description: "Short Profile of Muhammad Iqbal C. Rochman",
  },
}

const IndexPage = () => (
  <Layout>
    <div>
      <Seo postData={pageData} />
      <img style={{ float: 'right', margin: '0 1.7rem' }} src={Photo} alt="My Portrait" />

      <h2>About me</h2>

      <p>I am a graduate student researcher in the Computer Science department of the University of Chicago. Formerly <a href="https://sites.google.com/site/garudailmukomputer/about?authuser=0">Garuda Ilmu Komputer (GIK) Lab member</a>. I made <a href="https://people.cs.uchicago.edu/~muhiqbalcr/sigcap/">SigCap</a>.</p>

      <h2>Research Interests</h2>

      <ul>
        <li>Multi-RAT Coexistence</li>
        <li>Unlicensed Spectrum Reuse</li>
        <li>Next Generation Wireless Technology</li>
        <li>Wireless Network Optimization</li>
        <li>Smartphone-based Wireless Network Measurement</li>
      </ul>

      <h2>Education</h2>

      <ul>
        <li>Ph.D. in Computer Science, The University of Chicago (2024)</li>
        <li>Master in Computer Science (as a part of Ph.D.), The University of Chicago (2021)</li>
        <li>Master in Computer Science, National Taiwan University of Science and Technology (NTUST) Taiwan (2016)</li>
        <li>Bachelor in Informatics, Sepuluh Nopember Institute of Technology (ITS) Indonesia (2012)</li>
      </ul>

      <h2>Publications</h2>

      <p>For a full up-to-date list, please visit <a href="https://scholar.google.com/citations?user=X_OyZrIAAAAJ&hl=en">my Google Scholar Page</a>.</p>

      <ul>
        <li><strong>M.I. Rochman</strong>, V. Sathya, D. Fernandez, N. Nunez, A.S. Ibrahim, W. Payne, and M. Ghosh, "A comprehensive analysis of the coverage and performance of 4G and 5G deployments," in 2023 Elsevier Computer Networks. Preprint:<PdfLink target="rochman2023comprehensive-preprint-comnet" /></li>
        <li>S. Dogan-Tusha, <strong>M.I. Rochman</strong>, A. Tusha, H. Nasiri, J. Helzerman, and M. Ghosh, "Evaluating The Interference Potential in 6 GHz: An Extensive Measurement Campaign of A Dense Indoor Wi-Fi 6E Network," in 2023 ACM WiNTECH.<PdfLink target="dogantusha2023evaluating-wintech" /></li>
        <li><strong>M.I. Rochman</strong>, V. Sathya, W. Payne, M. Yavuz, and M. Ghosh, "A Measurement Study of the Impact of Adjacent Channel Interference between C-band and CBRS," in 2023 IEEE PIMRC.<PdfLink target="rochman2023cbandcbrs-pimrc" /></li>
        <li>V. Sathya, <strong>M.I. Rochman</strong>, T.V. Pasca, and M. Ghosh, "Impact of hidden node problem in association and data transmission for LAA Wi-Fi coexistence," in 2022 Elsevier Computer Communications. Preprint:<PdfLink target="sathya2022impact-preprint-comcom" /></li>
        <li><strong>M.I. Rochman</strong>, V. Sathya, and M. Ghosh, "Outdoor-to-Indoor Performance Analysis of a Commercial Deployment of 5G mmWave," in 2022 IEEE FNWF.<PdfLink target="rochman2022oti-fnwf" /></li>
        <li><strong>M.I. Rochman</strong>, D. Fernandez, N. Nunez, V. Sathya, A.S. Ibrahim, M. Ghosh, and W. Payne, "Impact of Device Thermal Performance on 5G mmWave Communication Systems," in 2022 IEEE CQR.<PdfLink target="rochman2022thermal-cqr" /></li>
        <li>A. Narayanan, <strong>M.I. Rochman</strong>, A. Hassan, B.S. Firmansyah, V. Sathya, M. Ghosh, F. Qian, Z.L. Zhang, "A Comparative Measurement Study of Commercial 5G mmWave Deployments," in 2022 IEEE INFOCOM.<PdfLink target="narayanan2022comparative-infocom" /></li>
        <li>A. Ramamurthy, V. Sathya, <strong>M.I. Rochman</strong>, M. Ghosh, "ML-Based Classification of Device Environment Using Wi-Fi and Cellular Signal Measurements," in 2022 IEEE Access.<PdfLink target="ramamurthy2022mlbased-ieeeaccess" /></li>
        <li><strong>M.I. Rochman</strong>, V. Sathya, N. Nunez, D. Fernandez, M. Ghosh, A.S. Ibrahim, and W. Payne, "A Comparison Study of Cellular Deployments in Chicago and Miami Using Apps on Smartphones," in 2021 ACM WiNTECH.<PdfLink target="rochman2021comparison-wintech" /></li>
        <li><strong>M.I. Rochman</strong>, Y. Anistyasari, "CONMIQ Multicast: A Scalable Multicast Video Streaming in LTE Networks," in 2021 ICVEE.<PdfLink target="rochman2021conmiq-icvee" /></li>
        <li>V. Sathya, <strong>M.I. Rochman</strong>, and M. Ghosh, "Hidden-nodes in Coexisting LAA & Wi-Fi: A Measurement Study of Real Deployments," in 2021 IEEE Workshop 16 ICC.<PdfLink target="sathya2021hidden-icc" /></li>
        <li>V. Sathya, <strong>M.I. Rochman</strong>, and M. Ghosh, "LAA/Wi-Fi Coexistence Issues: Wi-Fi Client Association and Data Transmission," presented at IEEE 802.11 Meeting Jan 13th, 2021, IEEE 802.11-20/1973r0.<PdfLink target="sathya2021laawifi-80211meeting" /></li>
        <li>M. Ghosh, V. Sathya, <strong>M.I. Rochman</strong>, and W. Payne, "Broadband Wireless Network Measurements Using Smartphones," in 2021 NSF Workshop on Overcoming Measurement Barriers to Internet Research (WOMBIR).<PdfLink target="ghosh2021broadband-wombir" /></li>
        <li>V. Sathya, <strong>M.I. Rochman</strong>, and M. Ghosh, "Measurement-Based Coexistence Studies of LAA & Wi-Fi Deployments in Chicago," in Wireless Communications 28 (1), pp. 136-143, 2020.<PdfLink target="sathya2020measurement-wicom" /></li>
        <li>V. Sathya, A. Ramamurthy, <strong>M.I. Rochman</strong>, and M. Ghosh, "QoS guaranteed radio resource scheduling in stand-alone unlicensed MulteFire," in 2020 IEEE 3rd 5G World Forum (5GWF), pp. 86-91.<PdfLink target="sathya2020qos-5gwf" /></li>
        <li>V. Sathya, S.M. Kala, <strong>M.I. Rochman</strong>, M. Ghosh, and S. Roy, "STANDARDIZATION ADVANCES FOR CELLULAR AND WI-FI COEXISTENCE in the Unlicensed 5 and 6 GHz Bands," in GetMobile: Mobile Computing and Communications 24 (1), pp. 5-15.<PdfLink target="sathya2020standardization-getmobile" /></li>
        <li>A. Dziedzic, V. Sathya, <strong>M.I. Rochman</strong>, M. Ghosh, and S. Krishnan, "Machine Learning for Fair Spectrum Sharing in Dense LTE Wi-Fi Coexistence," in IEEE Open Journal of Vehicular Technology 1, pp. 173-189, 2020.<PdfLink target="diedzic2020machine-vts" /></li>
        <li>M. Ghosh, V. Sathya, <strong>M.I. Rochman</strong>, M. Mehrnoush, and S. Roy, "Coexistence of LTE-LAA and Wi-Fi: Analysis simulation and experiments," presented in P802. 11 Coexistence SC Workshop, 2019.<PptLink target="ghosh2019coexistence-80211workshop" /></li>
        <li><strong>M.I. Rochman</strong>, V. Sathya, and M. Ghosh, "Impact of changing energy detection thresholds on fair coexistence of Wi-Fi and LTE in the unlicensed spectrum," 2017 Wireless Telecommunications Symposium (WTS), Chicago, IL, pp. 1-9.<PdfLink target="rochman2017impact-wts" /></li>
      </ul>

      <h2>Contact me</h2>

      <p>Email: muhiqbalcr at uchicago dot edu</p>
    </div>
  </Layout>
)

export default IndexPage
