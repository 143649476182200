import React from 'react'
import DlPptLogo from '../assets/dl-ppt.svg'

const PptLink = ({ target }) => (
  <div style={{ display: "inline-block", margin: "0px 0.4rem", width: "20px", }}>
    <a href={`../../papers/${target}.pptx`}>
      <img style={{ marginBottom: 0 }} src={DlPptLogo} alt="Download presentation" title="Download presentation" />
    </a>
  </div>
)

export default PptLink
